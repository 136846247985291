.full {
  position: relative;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.container {
  height: min-content;
  transition: all 0.5s ease;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.content {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.backgroundimage {
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 0%;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.overlay {
  position: fixed;
  opacity: 0.37;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.pagecontainer {
  height: min-content;
  position: relative;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  min-height: calc(100vh - 360px - 222px);
}

.banner {
  position: relative;
  padding-top: 88px;
  padding-right: 12px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 60% 50%;

  @media screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 20px;
    background-position: 60% 50%;
  }
}

.bannercontent {
  width: 38%;
  color: #000;
  text-align: start;
  font-weight: bold;
}
