.container {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1023px) {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  max-width: 1030px;

  @media screen and (max-width: 1023px) {
    align-items: center;
  }
}

@media (max-width: 1023px) {
  .content {
    flex-direction: column;
  }
}

.part1 {
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 25px;

  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
}

.part2 {
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 12px;
  font-size: 25px;

  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
}

.title {
  font-weight: 700;
  margin-bottom: 24px;
}

.divider {
  background-color: rgba(0, 0, 0, 1);
  width: 3px;
  height: 250px;

  @media screen and (max-width: 1023px) {
    height: 3px;
    width: 80%;
    margin-top: 24px;
    margin-bottom: 12px;
  }
}
