.container {
  position: relative;

  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.content {
  max-width: 800px;
  backdrop-filter: blur(8px);

  border-radius: 20px;
  background: rgba(239, 239, 239, 0.7);

  padding-top: 20px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 48px;

  margin-bottom: 60px;
}

.title {
  font-size: 60px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
}

.description {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 24px;
}

.answer {
  ul {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  a {
    text-decoration: underline;
  }
}
