.container {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  background-color: black;
  padding-top: 60px;
  padding-bottom: 60px;
  font-family: 'Inter';
}

.title {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (max-width: 600px) {
    font-size: 40px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #fff;
  border-radius: 20px;
  padding: 2.125rem;
  min-height: 290px;
  text-align: left;
  margin: 12px;
  gap: 12px;
}

.content {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.name {
  color: #ba61ff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.swiperButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-right: 20px;

  button {
    border: none;
    background: transparent;
    cursor: pointer;
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;

  @media screen and (max-width: 600px) {
    margin-top: 12px;
  }
}
