.container {
  position: relative;
  padding-left: 160px;
  padding-right: 160px;
  padding-bottom: 20px;
  text-align: center;

  @media screen and (max-width: 600px) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.content {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;

  @media screen and (max-width: 600px) {
    margin-top: 48px;
    gap: 24px;
  }
}

.title {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 1);

  @media screen and (max-width: 600px) {
    font-size: 40px;
  }
}

.descriptionBlack {
  color: rgba(0, 0, 0, 1);
  font-size: 25px;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 32px;

  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
}

.descriptionPurple {
  color: rgba(186, 97, 255, 1);
  font-size: 25px;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 32px;

  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
}

.button {
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: bold;
  border-radius: 100px;
  border: none;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(186, 97, 255, 1);
  cursor: pointer;
  height: 64px;
  padding-left: 32px;
  padding-right: 32px;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    height: 48px;
    padding-left: 24px;
    padding-right: 24px;
  }
}
