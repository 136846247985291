.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 5vw;
  padding-right: 5vw;
  /* min-height: 100vh; */
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  position: relative;

  padding-top: 55px;
  padding-bottom: 55px;
}

.content {
  background-color: rgba(230, 230, 230, 0.76);
  /* background-color: rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 25px 56px 0px rgba(0, 0, 0, 0.1),
    0px 100px 100px 0px rgba(0, 0, 0, 0.1);

  padding-left: 100px;
  padding-right: 100px;
  padding-top: 80px;
  padding-bottom: 40px;
  border-radius: 12px;

  /* width: max-content; */
  /* padding-bottom: 40px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 60px;
  /* width: 100%; */
  box-sizing: border-box;

  height: 100%;
  /* min-height: 100vh; */
  @media screen and (max-width: 800px) {
    padding-left: 20px;
    padding-right: 40px;
  }
}

div .textarea {
  font-size: 16px;
  font-family: 'Inter';
  padding: 16px;
  width: 100%;
  transition: all 0.1s;
  resize: none;
  box-sizing: border-box;

  /* slot */
  /* background-image: linear-gradient(
    to right,
    rgb(255, 255, 255),
    rgb(255, 225, 255)
  ); */
  background-color: rgba(255, 255, 255, 1);
  border: none !important;
  border-radius: 3px;
  box-shadow: inset 0px 1px 0px 0px rgba(125, 129, 159, 0.9);
  outline-style: none;
}

.question {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 16px;
}

.submitbutton {
  align-self: center;
  width: min-content;
  font-size: 16px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(186, 97, 255, 1);
  font-weight: bold;
  height: 32px;
  padding-top: 6px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  border-radius: 200px;
  border: none;
  cursor: pointer;
}
