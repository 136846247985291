.container {
  position: relative;
  margin-top: -40px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 600px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.frame {
  position: relative;
  max-width: 800px;
  backdrop-filter: blur(8px);
  border-radius: 20px;
  border: 6px solid #000;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: #fff;
}

.frame:hover {
  border-image: linear-gradient(
      90deg,
      #0b2048 0.61%,
      #7f64af 53.91%,
      rgba(127, 100, 175, 1) 99.31%
    )
    1;
  /* border: 6px solid transparent; */
  filter: brightness(1);
  background: linear-gradient(
    270deg,
    rgba(127, 100, 175, 1) 0.69%,
    #7f64af 46.09%,
    #0b2048 99.39%
  );

  .cta {
    background-color: rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, 1);
  }
}
.content {
  position: relative;

  display: flex;
  flex-direction: column;

  padding-top: 16px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 24px;

  @media screen and (max-width: 600px) {
    text-align: left;
  }

  .title {
    transition: all 0.2s ease-in-out;
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #000;
    @media screen and (max-width: 600px) {
      font-size: 30px;
    }
  }
  .subtitle {
    transition: all 0.3s ease-in-out;
    font-size: 20px;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
  }

  .description {
    transition: all 0.4s ease-in-out;
    font-size: 16px;
    margin-bottom: 24px;

    p {
      margin-bottom: 10px;
    }
  }
}

.content:hover {
  .title {
    color: rgba(255, 255, 255, 1);
  }
  .subtitle {
    color: rgba(255, 255, 255, 1);
  }

  .description {
    color: rgba(255, 255, 255, 1);
  }
}

.cta {
  background-color: #7f64af;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: bold;
  height: 64px;
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 200px;
  border: none;
  cursor: pointer;
  width: max-content;
  align-self: center;
  @media screen and (max-width: 600px) {
    align-self: flex-end;
    font-size: 16px;
  }
}
