.container {
  position: relative;
  /* height: 435px; */

  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* image background */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  @media screen and (max-width: 600px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.content {
  /* padding: 24px; */
  /* margin: 24px; */
  max-width: 800px;
  background-color: rgba(255, 255, 255, 0.76);
  backdrop-filter: blur(8px);
  border-radius: 16px;

  padding-top: 20px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 48px;

  margin-bottom: 60px;

  @media screen and (max-width: 600px) {
    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: none;
    padding-bottom: 0px;
  }
  /* outline: 1px solid rgba(200, 100, 100, 0.5); */
}

.title {
  font-size: 60px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 16px;

  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
}

.description {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 24px;
  /* color: rgba(255, 255, 255, 1); */
}
