.container {
  position: relative;

  padding-top: 88px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 60px;

  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.description {
  font-size: 24px;
  margin-bottom: 48px;
}

@media screen and (max-width: 600px) {
  .container {
    background-position: 0% 0%;
  }
  .content {
    min-width: 45%;
    padding-left: 60%;
    padding-top: 3%;
  }
  .description {
    margin-bottom: 0px;
  }
}

.content {
  min-width: 45%;
  padding-right: 10%;
}

.title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 24px;
  color: rgba(40, 41, 120, 1);
}
