.full {
  position: relative;
  /* Ensure it fills the entire element */
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}
.container {
  height: min-content;
  transition: all 0.5s ease;

  background-image: url('https://content.mentem.co/eoi/_share/form-bg.jpg');
  background-position: 100% 0%;
  background-repeat: no-repeat;
}
.content {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.backgroundimage {
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 0%;

  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.overlay {
  position: fixed;
  opacity: 0.37;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.pagecontainer {
  height: min-content;
  position: relative;

  width: 100%;

  max-width: 100vw;

  box-sizing: border-box; /* padding included in width */
  min-height: calc(
    100vh - 360px - 222px
  ); /*fill up the page if content inside is short*/
}

.banner {
  position: relative;

  padding-top: 88px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 60px;

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  /* background */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  @media screen and (max-width: 600px) {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

.bannercontent {
  min-width: 38%;
  padding-left: 10%;

  color: rgba(255, 255, 255, 1);
  text-align: end;

  font-weight: bold;
}
