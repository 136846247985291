.container {
  position: relative;
  /* height: 435px; */

  /* padding-top: 60px; */
  margin-top: -40px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 40px;
  /* padding-bottom: 60px; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  /* image background */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  @media screen and (max-width: 600px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.frame {
  position: relative;
  max-width: 800px;
  backdrop-filter: blur(8px);
  border-radius: 16px;

  /* border: 6px solid rgba(186, 97, 255, 1); */
  border: 6px solid rgba(104, 83, 197, 1);

  .bg {
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    filter: brightness(6);
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(83.55deg, #ba61ff 1%, #3d3eb7 99.56%);
  }
  cursor: pointer;
}
.frame:hover {
  .bg {
    filter: brightness(1);
  }
}
.content {
  position: relative;

  display: flex;
  flex-direction: column;

  padding-top: 16px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 24px;

  @media screen and (max-width: 600px) {
    text-align: left;
  }

  .title {
    transition: all 0.2s ease-in-out;
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 8px;
    /* color: rgba(186, 97, 255, 1); */
    color: rgba(43, 57, 128, 1);
    @media screen and (max-width: 600px) {
      font-size: 30px;
    }
  }
  .subtitle {
    transition: all 0.3s ease-in-out;
    font-size: 20px;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
  }

  .description {
    transition: all 0.4s ease-in-out;
    font-size: 16px;
    /* font-weight: bold; */
    margin-bottom: 24px;

    p {
      margin-bottom: 10px;
    }

    /* color: rgba(255, 255, 255, 1); */
  }
}

.content:hover {
  .title {
    color: rgba(255, 255, 255, 1);
  }
  .subtitle {
    color: rgba(255, 255, 255, 1);
  }

  .description {
    color: rgba(255, 255, 255, 1);
  }
}

.cta {
  /* margin-left: 0px; */
  background-color: rgba(186, 97, 255, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: bold;
  height: 64px;
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 200px;
  border: none;
  cursor: pointer;
  width: max-content;
  align-self: center;
  @media screen and (max-width: 600px) {
    align-self: flex-end;
    font-size: 16px;
  }
}
