.container {
  position: relative;
  /* height: 435px; */

  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 60px;
  /* background */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  text-align: center;
}

/* start arrangement */
.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  width: 100%;
}

@media (max-width: 1023px) {
  .content {
    flex-direction: column;
  }
}

.part1 {
  flex: 1;
}

.part2 {
  flex: 1;
}

/* end arrangement */

.image {
  width: 100%;
}

.title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 24px;

  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
}

.description {
  font-size: 25px;
  margin-bottom: 48px;
  text-align: left;

  @media screen and (max-width: 600px) {
    text-align: left;
  }
}
