.container {
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

.description {
  font-size: 20px;

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
}

.downloadcta {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  padding-top: 8px;
  padding-left: 43px;
  padding-right: 43px;
  padding-bottom: 8px;
  border-radius: 100px;
  border: none;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(186, 97, 255, 1);
  cursor: pointer;
  min-height: 64px;
  min-width: 250px;

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
}
