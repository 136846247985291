.container {
  position: relative;

  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  background-color: rgba(0, 0, 0, 1);

  @media screen and (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.content {
  padding-top: 16px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 24px;

  color: rgba(255, 255, 255, 1);
}

.items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32px;

  .itemtitle {
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
  }

  margin-bottom: 24px;
}

.description {
  font-size: 16px;
}
