:root {
  --bar-height: 90px;
}
* {
  scroll-margin-top: 90px;
}

.container {
  height: var(--bar-height); /* occupy space for subsequent content */
}

.content {
  height: var(--bar-height); /* bar display height */
  position: fixed;
  z-index: 10000;
  width: 100%;

  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 1);
  align-items: center;
  justify-content: space-between;
  padding-left: 4%;
  padding-right: 5%;
  box-sizing: border-box;
}

.logo {
  height: auto;
}

/* mid size adjustments */
@media (max-width: 799px) {
  .logo {
    width: 120px;
  }
  .content {
    padding-left: 1%;
    padding-right: 2%;
  }
}
@media (max-width: 599px) {
  .content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* desktop */
.desktopitems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  align-items: center;
  gap: 8px;
}

.desktopitem {
  font-size: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;

  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}

.desktopitem:hover {
  color: rgba(186, 97, 255, 1);
}

.cta {
  margin-left: 0px;
  color: rgba(186, 97, 255, 1);
  font-size: 16px;
  font-weight: bold;
  height: 46px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 200px;
  border: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 1);
}

.cta:hover {
  background-color: rgba(186, 97, 255, 1);
  color: rgba(255, 255, 255, 1);
}

.menubutton {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
}

/* mobile */
@media (max-width: 599px) {
  .desktopitems {
    display: none;
  }
  .logo {
    width: 116px;
  }
  .menubutton {
    display: block;
  }
}

.mobileitems {
  display: flex;
  flex-direction: column;
  background-color: #000;
}

.mobileitem {
  background: none;
  border: none;
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  font: 16px;
  font-weight: bold;

  height: 60px;
  text-align: end;
  padding-right: 30px;
}

.mobileitem:hover {
  background-color: rgba(186, 97, 255, 1);
}

.logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 64px;
}

.menuItemContainer {
  position: relative;
  display: inline-block;
}

.mobileMenuItemContainer {
  width: 100%;
  text-align: end;
}

.submenu {
  display: none; /* Hidden by default */
  position: absolute;
  top: 100%; /* Position submenu below the menu item */
  left: 0;
  background-color: #000;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 2;
  padding: 1rem;
}

.mobileSubmenu {
  display: black;
  background-color: #000;
}

.menuItemContainer:hover .submenu {
  display: block; /* Show submenu on hover */
}

.submenuBackground {
  display: none; /* Hidden by default */
  position: absolute;
  top: 100%; /* Position submenu below the menu item */
  left: 0;
  background-color: #000;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 1rem;
  width: 100%;
}

.desktopitems:hover .submenuBackground {
  display: block; /* Show submenu on hover */
}

.submenuItem {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
}

.mobileSubmenuItem {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  padding-right: 48px;
  text-align: end;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
}

.submenuItem:hover {
  color: rgba(186, 97, 255, 1); /* Optional hover effect for submenu items */
}
