.container {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1023px) {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.title {
  font-size: 25px;
  font-weight: 700;
}
