.container {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
