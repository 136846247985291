.container {
  position: relative;
  padding-bottom: 60px;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: auto;
}

.table {
  margin: auto;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);

  .dark {
    background-color: rgba(243, 243, 245, 1);
  }
}
