.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 5vw;
  padding-right: 5vw;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  position: relative;

  padding-top: 55px;
  padding-bottom: 55px;
}

.content {
  background: rgba(242, 239, 237, 0.6);
  box-shadow: 0px 25px 56px 0px rgba(0, 0, 0, 0.1),
    0px 100px 100px 0px rgba(0, 0, 0, 0.1);

  padding-left: 100px;
  padding-right: 100px;
  padding-top: 60px;
  padding-bottom: 40px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  box-sizing: border-box;
  height: 100%;

  @media screen and (max-width: 800px) {
    padding-left: 20px;
    padding-right: 40px;
  }
}

.main {
  background-color: rgba(255, 255, 255, 1);
  padding: 24px;
  border-radius: 6px;
}

.question {
  font-size: 14px;
  font-weight: 600;
  /* font-family: 'Roboto'; */
  line-height: 20px;
  padding-bottom: 16px;
}

.submitbutton {
  align-self: center;
  width: min-content;
  font-size: 16px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 1);
  background-color: #8064af;
  font-weight: bold;
  height: 32px;
  padding-top: 6px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  border-radius: 200px;
  border: none;
  cursor: pointer;
}

.checkboxcontainer {
  display: flex;
  flex-direction: column;
}
