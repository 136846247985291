.container {
  display: block;
  width: 100%;
}

.heroImage {
  position: relative;
  height: 100%;
  min-height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.heroLogo {
  position: relative;
  height: 100%;
  min-height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('images/logo-white.svg');
}

.heroText {
  width: auto;
  text-align: left;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #000000;
  margin-top: 1%;
  margin-left: 45%;
  margin-right: 2%;

  @media only screen and (max-width: 1200px) {
    margin-left: 43%;
  }

  @media only screen and (max-width: 1024px) {
    margin-left: 41%;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 3%;
    margin-left: 41%;
  }

  @media only screen and (max-width: 574px) {
    margin-top: 3%;
    margin-left: 39%;
  }
}

.description {
  font-size: 25px;
  margin-bottom: 24px;
}

.title {
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 24px;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .description {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .title {
    font-size: 36px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    height: 400px;
  }
  .title {
    font-size: 32px;
  }
}
