.container {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* start arrangement */
.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch; /* Ensure children stretch to the height of the parent */
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  max-width: 1050px;

  @media screen and (max-width: 1023px) {
    align-items: center;
  }
}

@media (max-width: 1023px) {
  .content {
    flex-direction: column;
  }
}

.part1 {
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding-left: 12px;
  padding-right: 12px;
}

.part2 {
  flex: 1;
  text-align: left;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding-left: 20px;
  padding-right: 12px;
}

/* end arrangement */

.image {
  width: 100%;
}

.title {
  font-size: 46px;
  font-weight: bold;
  margin-bottom: 24px;
  /* mobile verion */
  @media screen and (max-width: 767px) {
    font-size: 32px;
  }
}

.description {
  font-size: 24px;
  margin-bottom: 48px;
}

.divider {
  background-color: rgba(0, 0, 0, 1);
  width: 3px;

  @media screen and (max-width: 1023px) {
    height: 3px;
    width: 80%;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.list {
  ul {
    list-style-type: none; /* Remove default bullets */
  }

  li {
    position: relative;
    padding-left: 24px; /* Space for custom dot */
    margin-bottom: 32px;
  }

  li::before {
    content: '•'; /* Custom bullet point */
    position: absolute;
    left: 0;
    top: 0px;
  }
}
