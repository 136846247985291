.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 5vw;
  padding-right: 5vw;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  position: relative;

  padding-top: 55px;
  padding-bottom: 55px;
}

.content {
  background: rgba(230, 230, 230, 0.76);
  border-radius: 12px;
  padding-left: 100px;
  padding-right: 100px;
  @media screen and (max-width: 800px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  padding-top: 24px;
  padding-bottom: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main {
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 24px;
  padding-bottom: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  box-sizing: border-box;

  height: 100%;

  font-weight: bold;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  color: black;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  line-height: 20px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
}

.submitbutton {
  align-self: center;
  width: min-content;
  font-size: 16px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(186, 97, 255, 1);
  font-weight: bold;
  height: 32px;
  padding-top: 6px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  border-radius: 200px;
  border: none;
  cursor: pointer;
}

.img {
  width: 120px;
  height: auto;
  align-self: center;
}
