.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  height: 100vh;
  width: 100vw;
}

.error {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.message {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 10%;
  padding-right: 10%;
}

@media screen and (max-width: 768px) {
  .message {
    font-size: 18px;
    font-weight: 500;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 36px;
}

.actionButtonPrimary {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: bold;
  font-family: 'Roboto';
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 200px;
  border: 1px solid rgba(186, 97, 255, 1);
  cursor: pointer;
  background-color: rgba(186, 97, 255, 1);
}

.actionButtonPrimary:hover {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(186, 97, 255, 1);
  border: 1px solid rgba(186, 97, 255, 1);
}

.actionButtonSecondary {
  color: #3c415d;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Roboto';
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 200px;
  border: 1px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 16px 24px 0px rgba(48, 49, 51, 0.1);
  cursor: pointer;
  background-color: rgba(255, 255, 255, 1);
}

.actionButtonSecondary:hover {
  background-color: rgba(186, 97, 255, 1);
  color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(186, 97, 255, 1);
}

@keyframes fadeInSlide {
  0% {
    opacity: 0;
    transform: translateX(-50px); /* Start off the left */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* Move to the original position */
  }
}

.triggerAnimation {
  animation: fadeInSlide 1s ease-in-out forwards; /* This class can be applied conditionally */
}
