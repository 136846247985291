@media (max-width: 1023px) {
  .title {
    font-size: 30px !important;
  }
  .itemTitle {
    font-size: 20px !important;
  }
  .itemDescription {
    font-size: 18px !important;
  }
}

.content {
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 0;
  }
}

.divider {
  background-color: rgba(0, 0, 0, 1);
  width: 5px;
  height: 270px;
  align-self: center;

  @media screen and (max-width: 1023px) {
    height: 3px;
    width: 80%;
    margin-top: 24px;
    margin-bottom: 12px;
  }
}
