.container {
  position: relative;
  /* height: 435px; */

  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 60px;
  /* background */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  text-align: center;
  @media screen and (max-width: 600px) {
    padding: 0;
  }
}

.content {
  top: 140px;
  /* padding: 24px; */
  /* margin: 24px; */
  /* background-color: rgba(200, 100, 100, 0.1); */
  /* outline: 1px solid rgba(200, 200, 200, 0.7); */
}

.title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 16px;

  @media screen and (max-width: 600px) {
    font-size: 27px;
    background-color: rgba(41, 40, 120, 1);
    color: rgba(255, 255, 255, 1);
    height: 57px;
    /* vertical text align */
    display: grid;
    place-items: center;
  }
}

.description {
  /* margin-top: 16px; */
  font-size: 24px;
  margin-bottom: 48px;
}

.boxesstack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;

  margin-bottom: 24px;
}
.item {
  width: 360px;

  /* aspect-ratio: 1 / 1; */
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(220, 220, 220, 0.4);
  /* frost effect */
  backdrop-filter: saturate(0.8) blur(12px);

  border-radius: 24px;
  box-sizing: border-box;

  .boximage {
    object-fit: contain;

    /* margin-bottom: 16px; */
  }
  .boxtitle {
    font-size: 35px;
    font-weight: bold;
    /* margin-bottom: 8px; */
  }
  .boxdescription {
    padding-top: 12px;
    font-size: 20px;
    /* height: 32px; */
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 16px;
  }
}
