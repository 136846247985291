.full {
    position: relative;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
  }
  
  .container {
    height: min-content;
    transition: all 0.5s ease;
  
    background-image: url('https://content.mentem.co/eoi/_share/form-bg.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .content {
    position: relative;
    width: 100%;
    min-height: 100vh;
  }
  
  .backgroundimage {
    position: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 0%;
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }
  
  .overlay {
    position: fixed;
    opacity: 0.37;
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }
  
  .pagecontainer {
    height: min-content;
    position: relative;
  
    width: 100%;
  
    max-width: 100vw;
    box-sizing: border-box;
    min-height: calc(100vh - 360px - 222px);
  }
  
  .banner {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    min-height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 20% 50%;
  
    padding-left: 14%;
    padding-right: 0%;
  
    @media screen and (max-width: 768px) {
      padding-left: 0%;
      padding-right: 50%;
    }
  
    @media screen and (max-width: 600px) {
      padding-left: 0%;
      padding-right: 35%;
    }
  }
  
  .bannercontent {
    min-width: 25%;
    width: 25%;
    color: rgba(255, 255, 255, 1);
    text-align: end;
    font-weight: bold;
    /* background-color: red; */
    /* text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9); */
  
    @media screen and (max-width: 768px) {
      width: 100%;
      padding-left: 0;
    }
  }
  