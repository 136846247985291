.container {
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

.content {
}

.title {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 8px;
}

.subtitle {
  font-size: 16px;
  color: rgba(186, 97, 255, 1);
  font-weight: bold;
  margin-bottom: 16px;
}

.description {
  color: rgba(0, 0, 0, 1);
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 32px;
}

.boxesstack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 80px;
  /* margin-bottom: 24px; */
}

.block {
  width: 240px;
  border: 1px solid rgba(128, 128, 128, 0.8);
  background-color: rgba(255, 255, 255, 1);
  .itemimage {
    width: 100%;
    height: auto;
  }
  .itemtitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;

    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .itemdescription {
    font-size: 16px;
  }
}

.block2 {
  width: 380px;
  border: 1px solid rgba(128, 128, 128, 0.8);
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .itemimage {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  .blockcontainer {
    z-index: 100;
    position: absolute;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }

  .frame {
    background-color: rgba(255, 255, 255, 0.9);
    padding-top: 12px;
    padding-bottom: 8px;
  }

  .itemtitle {
    font-size: 27px;
    font-weight: bold;
    margin-bottom: 8px;

    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 4px;
    /* height: 160px; */
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }
  .itemdescription {
    font-size: 16px;
  }
}

.itemcta {
  font-size: 16px;
  font-weight: bold;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  border-radius: 100px;
  border: none;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(186, 97, 255, 1);
  margin-bottom: 16px;
  cursor: pointer;
}
