.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 5vw;
  padding-right: 5vw;
  /* min-height: 100vh; */
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  position: relative;

  padding-top: 55px;
  padding-bottom: 55px;
}

.content {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  max-width: min(718px, 100%);
  border-radius: 16px;

  min-width: 55vw;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: saturate(1) blur(0px);

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* min-height: 100vh; */

  box-shadow: 0px 22px 48px rgba(0, 0, 0, 0.1);

  padding: 60px 60px;
  /* mobile padding*/
  @media screen and (max-width: 600px) {
    padding: 20px 20px;
  }
}

/* form */
.title {
  font-size: 3rem;
  margin: 0;
  padding: 0;
  color: #333;
  font-weight: 700;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.subtitle {
  margin-top: 8px;
  font-size: 1.5rem;
  color: #333;
  font-weight: 700;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}
.description {
  margin-top: 24px;
  max-width: 800px;
  font-size: 1rem;
  color: #333;
  font-weight: 700;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1);
}
.instructions {
  margin-top: 24px;
  max-width: 800px;
  font-size: 1rem;
  color: #333;
  font-weight: 500;
}
.formcontainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 16px;
  align-items: center;
}

.checkboxcontainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  /* text-align: left; */
  @media screen and (max-width: 600px) {
    max-width: 80%;
  }
}
.error {
  position: absolute;
  color: red;
  font-size: 0.8rem;
  right: -48px;
  /* top: -6px; */
  /* font-weight: 700; */
  /* text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1); */
}
.submitbutton {
  font-size: 16px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(186, 97, 255, 1);
  font-weight: bold;
  height: 32px;
  padding-top: 6px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  border-radius: 200px;
  border: none;
  cursor: pointer;
}
